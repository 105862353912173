.glass {
  background: rgba(255, 255, 255, 0.2);

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
  padding: 0px;
  height: 100%;
  width: 100%;
  text-align: center;
}
.glass2 {
  background: rgba(255, 255, 255, 0);

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.075);
  backdrop-filter: blur(10px);
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
  padding: 20px;
  height: 100%;
  width: 100%;
  text-align: center;
}

.robotobold {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  /* font-weight: bold; */
  font-size: 6vh;
  line-height: 1.5;
  color: white;
  text-align: left;
  padding-left: 7vh;
}
.span {
  color: yellowgreen;
}

@media (max-width: 576px) {
  .responsivetext1 {
    font-size: 2rem;
    padding-left: 15px;
    /* height: 50vh !important; */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .responsive-text {
    font-size: 1.5rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .responsive-text {
    font-size: 2rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .responsive-text {
    font-size: 2.5rem;
  }
}

/* card */
.cardhover {
  transition: transform 0.3s;
}

.cardhover1:hover {
  transform: scale(1.05);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}