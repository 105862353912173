body {
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(248, 249, 250);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.box {
  background-color: red;
}
.nav-link:hover{
  color: #0af86f !important;
}
.nav-link:focus{
  font-weight: 500;
  color: rgb(4,114,49);
  border-bottom-width: 5px;
  border-color: rgb(4,114,49);
  
  
}
.dropdown-item:active {
  background-color:rgb(10,248,111);
  }
.dropdown-toggle:hover{
  background-color:rgb(10,248,111);
}
.dropdown-toggle:out-of-range{
  background-color:rgb(10,248,111);
}
